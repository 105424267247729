import React, {useMemo} from "react";
import useCombinedClassName from "../../../../hooks/useCombinedClassName";
import useCombinedStyle from "../../../../hooks/useCombinedStyle";
import styles from './style.module.css';

interface SubtitleProps {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    size: 'XS' | 'S' | 'M' | 'L' | 'XL';
    color?: React.CSSProperties['color'];
    maxLines?: number;
}

const Subtitle = (props: SubtitleProps) => {

    const {children, className, style, color, size, maxLines} = props;

    const fontSizeClass = useMemo(() => {
        const classMap = {
            'XS': styles.fontXs,
            'S': styles.fontSm,
            'M': styles.fontMd,
            'L': styles.fontLg,
            'XL': styles.fontXl
        };
        return classMap[size] || styles.fontMd;
    }, [size]);

    const combinedClass = useCombinedClassName(styles.subtitle, className, fontSizeClass, maxLines ? styles.clamp : '');
    const combinedStyle = useCombinedStyle(style, {color, WebkitLineClamp: maxLines});

    return (
        <h2 className={combinedClass} style={combinedStyle}>{children}</h2>
    );

}
export default Subtitle;
