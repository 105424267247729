import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Article from '../../models/Article';
import ApiManager from '../../manager/ApiManager';
import Paragraph from '../../components/Base/Headings/Paragraph';
import FlatList from '../../components/Base/Misc/FlatList';
import CardArticleSmall from '../../components/Cells/CardArticleSmall';
import FilterItem from '../../models/FilterItem';
import View from '../../components/Base/View';
import Category from '../../models/Category';
import Breadcrumb from '../../models/Breadcrumb';
import useChunkArray from '../../hooks/useChunkArray';
import { useFilterArticlesByBrand } from '../../hooks/useFilterArticlesByBrand';
import useNavigation from '../../hooks/useNavigation';
import ArticlesTemplate from '../../templates/ArticlesTemplate';

const CategoryPage = () => {
    const { slug } = useParams<{ slug: string }>();
    const [category, setCategory] = useState<Category>();
    const [articles, setArticles] = useState<Article[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
    const [sidebarItems, setSidebarItems] = useState<FilterItem[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const navigation = useNavigation();

    /*
     * API: Fetch Data
     * */
    const fetchData = async () => {
        if (slug) {
            setIsLoading(true);
            try {
                const response = await ApiManager.getCategoryArticles(slug);
                setCategory(response.category);
                setArticles(response.articles);
                setBreadcrumbs(response.breadcrumbs);
                setSidebarItems(response.filters);
            } catch (e) {
                console.log(e);
                navigate('*');
            } finally {
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [slug]);

    /*
     * Filters Methods
     * */
    const filters = useMemo(() => {
        const brandsSet = new Set<string>();
        const brands: FilterItem[] = [];

        articles.forEach((article) => {
            if (article.brand && !brandsSet.has(article.brand.id.toString())) {
                brandsSet.add(article.brand.id.toString());
                brands.push(article.brand);
            }
        });

        brands.sort((a, b) => a.name.localeCompare(b.name));
        return brands;
    }, [articles]);
    const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
    const filteredArticles = useFilterArticlesByBrand(articles, activeFilters);
    const articleChunks = useChunkArray(filteredArticles.length === 0 ? articles : filteredArticles, 4);
    const onFilterClick = (items: FilterItem[]) => {
        setActiveFilters(items);
    };

    /*
     * FlatList methods
     * */
    const renderArticle = (item: Article, index: number) => {
        return <CardArticleSmall article={item} onPress={() => navigation.navigateToArticle(item.slug)} />;
    };
    const keyExtractor = (item: { id: number }, index: number) => {
        return item.id.toString();
    };

    /*
     * Template props
     * */
    const headerProps = {
        title: category?.name,
    };
    const filterProps = {
        items: filters,
        onFilterClick,
    };
    const sidebarProps = {
        items: sidebarItems,
        currentId: category?.id,
    };

    return (
        <ArticlesTemplate header={headerProps} filters={filterProps} sidebar={sidebarProps} breadcrumbs={breadcrumbs}>
            <View id={'category-articles'} flexDirection={'column'} gap={16}>
                {articleChunks.length > 0 &&
                    articleChunks.map((chunk, index) => <FlatList key={index} horizontal data={chunk} renderItem={renderArticle} keyExtractor={keyExtractor} elements={4} minElements={2} gap={16} />)}
                {articleChunks.length === 0 && <Paragraph size={'L'}>Nessun risultato</Paragraph>}
            </View>
        </ArticlesTemplate>
    );
};
export default CategoryPage;
