import React from 'react';
import styles from './style.module.css';

const ArticlesPage = () => {
    return (
        <div>
            <h1>Articles Page</h1>
        </div>
    );
}

export default ArticlesPage;
