const Colors = {
    APP_GRAY: '#313838',
    APP_MAIN_COLOR: '',
    APP_ACCENT_COLOR: '#dd6351',
    APP_BACKGROUND_MAIN: '#0e0e0e',
    APP_BACKGROUND_SECONDARY: '#1c1c1e',
    APP_SEPARATOR_COLOR: '#000000',
    YELLOW: '#fabe00',
    RED: '#e24444',
    MAGENTA: '#ff008d',
    PURPLE: '#9639b7',
    GREEN: '#4fca55',
    BLACK: '#000000',
    BLACK90: '#1c1c1e',
    BLACK75: '#2c2c2e',
    BLACK50: '#3a3a3c',
    BLACK25: '#48484a',
    BLACK10: '#636366',
    WHITE: '#ffffff',
    WHITE90: '#f2f2f7',
    WHITE75: '#e5e5ea',
    WHITE50: '#d1d1d6',
    WHITE25: '#c7c7cc',
    WHITE10: '#aeaeb2',
    TRANSPARENT: 'transparent',
};
export default Colors;
