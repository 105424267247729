import React from 'react';
import View from '../Base/View';
import Subtitle from '../Base/Headings/Subtitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Paragraph from '../Base/Headings/Paragraph';
import { AlertType } from '../../models/enums/AlertType';
import styles from './style.module.css';
import useCombinedClassName from '../../hooks/useCombinedClassName';

interface AlertProps {
    title: string | null;
    message: string | null;
    type: AlertType | null;
    visible: boolean;
    onClose?: () => void;
}

const Alert = (props: AlertProps) => {
    const { title, message, type, visible, onClose } = props;

    const typeClass = () => {
        switch (type) {
            case AlertType.success:
                return styles.success;

            case AlertType.error:
                return styles.error;

            case AlertType.warning:
                return styles.warning;

            default:
                return '';
        }
    };

    const combinedClass = useCombinedClassName(styles.alert, visible ? styles.visible : styles.hidden, typeClass());

    return (
        <View className={combinedClass} flexDirection={'column'}>
            <View flexDirection={'row'} alignItems={'center'}>
                <View flex={1} className={styles.title}>
                    <Subtitle size={'M'}>{title}</Subtitle>
                </View>
                <View justifyContent={'flex-end'}>
                    <View onClick={onClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </View>
                </View>
            </View>
            <View flexDirection={'column'}>
                <Paragraph size={'S'}>{message}</Paragraph>
            </View>
        </View>
    );
};
export default Alert;
