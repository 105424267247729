import React from 'react';
import View from '../../../../components/Base/View';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Paragraph from '../../../../components/Base/Headings/Paragraph';
import Colors from '../../../../toolkit/Colors';
import Article from '../../../../models/Article';
import Breadcrumb from '../../../../models/Breadcrumb';
import Title from '../../../../components/Base/Headings/Title';

interface ArticleTitleProps {
    article?: Article;
    breadcrumbs: Breadcrumb[];
}

const ArticleTitle = (props: ArticleTitleProps) => {
    const { article, breadcrumbs } = props;

    return (
        <View id={'article-title'} isBoxed paddingTop={32} isSection style={{ gap: 32 }} flexDirection={'column'}>
            <View flexDirection={'row'}>
                <View flexDirection={'column'}>
                    <View flexDirection={'row'} marginBottom={8}>
                        <Breadcrumbs elements={breadcrumbs} />
                    </View>
                    <Title size={'XL'}>{article?.name}</Title>
                    {article?.short_description && (
                        <View marginTop={4}>
                            <Paragraph size={'M'} color={Colors.BLACK25}>
                                {article?.short_description}
                            </Paragraph>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};
export default ArticleTitle;
