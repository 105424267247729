import React from 'react';
import MyAccountTemplate from '../../templates/MyAccountTemplate';
import View from '../../components/Base/View';
import Paragraph from '../../components/Base/Headings/Paragraph';
import styles from './style.module.css';

const SettingsPage = () => {
    return (
        <MyAccountTemplate>
            <View>
                <Paragraph size={'L'}>SettingsPage</Paragraph>
            </View>
        </MyAccountTemplate>
    );
};
export default SettingsPage;
