import React, {createContext, useContext, useEffect, useState} from "react";
import User from "../models/User";

interface UserContextType {
    user: User | null,
    setUser: (user: User | null) => void,
    clearUser: () => void
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
    children: React.ReactNode
}

export const UserProvider = (props: UserProviderProps) => {

    const {children} = props;
    const [user, setUser] = useState<User | null>(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    const clearUser = () => {
        setUser(null);
    };

    return (
        <UserContext.Provider value={{user, setUser, clearUser}}>
            {children}
        </UserContext.Provider>
    );

}

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}
