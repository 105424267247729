import React from 'react';
import styles from './style.module.css';
import View from '../../../../components/Base/View';
import Subtitle from '../../../../components/Base/Headings/Subtitle';
import LineChart from '../../../../components/LineChart';
import Separator from '../../../../components/Separator';

const PriceHistory = () => {
    return (
        <View isBoxed id={'price-history'} paddingBottom={32} flexDirection={'column'}>
            <Separator marginBottom={16} />
            <View flexDirection={'column'}>
                <View marginBottom={16}>
                    <Subtitle size={'L'}>Price History</Subtitle>
                </View>
                <View flex={1} className={styles.chartContainer}>
                    <LineChart />
                </View>
            </View>
        </View>
    );
};
export default PriceHistory;
