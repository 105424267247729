import React, {useMemo} from 'react';

function useCombinedStyle(...styles: (React.CSSProperties | undefined)[]): React.CSSProperties {
    return useMemo(() => {
        return styles.reduce((acc, style) => {
            return {...acc, ...style};
        }, {});
    }, [styles]) as React.CSSProperties;
}

export default useCombinedStyle;
