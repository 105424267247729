import React from 'react';
import styles from './style.module.css';
import View from '../Base/View';
import useCombinedStyle from '../../hooks/useCombinedStyle';

interface SeparatorProps {
    marginTop?: React.CSSProperties['marginTop'];
    marginBottom?: React.CSSProperties['marginBottom'];
}

const Separator = (props: SeparatorProps) => {
    const { marginTop, marginBottom } = props;
    const combinedStyle = useCombinedStyle({ marginTop, marginBottom });

    return (
        <View style={combinedStyle} className={styles.container} isBoxed>
            <></>
        </View>
    );
};
export default Separator;
