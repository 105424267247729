import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import useCombinedClassName from "../../../../hooks/useCombinedClassName";
import styles from './flatlist.module.css';

interface FlatListProps<T> {
    data: T[];
    renderItem: (item: T, index: number) => React.ReactNode;
    keyExtractor: (item: T, index: number) => string;
    gap?: number;
    elements: number;
    horizontal?: boolean;
    style?: React.CSSProperties;
    minElements?: number; //Minimum number of elements to shop on responsive
    autoplay?: boolean;
    autoplayInterval?: number;
    noOverlap?: boolean; //Se specificato, evita che sul responsive mostri la parte di slide successiva
}

const FlatList = <T, >(props: FlatListProps<T>) => {

    const {
        data,
        renderItem,
        keyExtractor,
        horizontal,
        gap = 0,
        elements,
        minElements,
        autoplay,
        autoplayInterval,
        noOverlap = false
    } = props;
    const sectionRef = useRef<HTMLDivElement>(null);
    const [sectionWidth, setSectionWidth] = useState<number | undefined>(undefined);
    const [cardWidth, setCardWidth] = useState<number>();
    const [scrollPosition, setScrollPosition] = useState(0);
    const MIN_ELEMENTS = minElements ? minElements : 2;
    const MIN_WIDTH = 768; //TODO: Centralizzare insieme ai valori nei file css per il responsive

    const flexDirectionClass = useMemo(() => {
        return horizontal ? styles.row : styles.column;
    }, [horizontal]);

    const overFlowClass = useMemo(() => {
        return horizontal ? styles.overFlowXAuto : styles.overFlowYAuto;
    }, [horizontal]);

    const whiteSpaceClass = useMemo(() => {
        return horizontal ? styles.noWrap : styles.wrapNormal
    }, [horizontal]);

    const combinedClass = useCombinedClassName(styles.flatList, flexDirectionClass, overFlowClass, whiteSpaceClass);

    /*
    * Get section width
    * */
    const updateSectionWidth = () => {
        if (sectionRef.current) {
            setSectionWidth(sectionRef.current.getBoundingClientRect().width);
        }
    };
    useLayoutEffect(() => {
        updateSectionWidth();
        window.addEventListener('resize', updateSectionWidth);

        return () => {
            window.removeEventListener('resize', updateSectionWidth);
        };
    }, [data, sectionRef]);

    /*
    * Set card width based on section width
    * */
    useLayoutEffect(() => {
        if (sectionWidth) {
            const maxElements = sectionWidth >= MIN_WIDTH ? elements : MIN_ELEMENTS;
            const gapsWidth = maxElements * gap;
            const cardWidth = ((sectionWidth - gapsWidth) / maxElements);

            if (sectionWidth >= MIN_WIDTH) {
                setCardWidth(cardWidth);
            } else {
                /*
                * Serve per mostrare sul responsive uno spicchio di card in più per far capire che è uno scroll
                * */
                if (noOverlap) {
                    setCardWidth(cardWidth - (cardWidth / 100) + 5);
                } else {
                    setCardWidth(cardWidth - ((cardWidth / 100) * 20));
                }
            }
        }

    }, [sectionRef, elements, gap, sectionWidth, MIN_ELEMENTS, noOverlap]);

    /*
    * Handle scroll to update current slide
    * */
    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const scrollContainer = sectionRef.current;
                const newPosition = horizontal ? scrollContainer.scrollLeft : scrollContainer.scrollTop;
                setScrollPosition(newPosition);
            }
        };

        const scrollContainer = sectionRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [horizontal]);

    /*
    * Auto-scroll functions
    * */
    useEffect(() => {
        if (autoplay && sectionRef.current && cardWidth) {
            const interval = autoplayInterval || 3000; // Default a 3000ms se autoplayInterval non è specificato
            const scrollContainer = sectionRef.current;
            const scrollStep = cardWidth + gap;

            const autoplayScroll = () => {
                let newPosition = scrollPosition + scrollStep;
                if (horizontal) {
                    if (newPosition >= scrollContainer.scrollWidth) {
                        newPosition = 0;
                    }
                    scrollContainer.scrollTo({left: newPosition, behavior: 'smooth'});
                } else {
                    if (newPosition >= scrollContainer.scrollHeight) {
                        newPosition = 0;
                    }
                    scrollContainer.scrollTo({top: newPosition, behavior: 'smooth'});
                }
                setScrollPosition(newPosition);
            };

            const intervalId = setInterval(autoplayScroll, interval);

            return () => clearInterval(intervalId); // Cleanup l'intervallo quando il componente viene smontato
        }
    }, [autoplay, autoplayInterval, cardWidth, gap, horizontal, scrollPosition]);

    return (
        <div ref={sectionRef} style={{gap}} className={combinedClass}>
            {data.map((item, index) => (
                <div
                    id={'item-' + keyExtractor(item, index)}
                    key={keyExtractor(item, index)}
                    style={{
                        width: cardWidth,
                    }}
                    className={styles.snapItem}
                >
                    {renderItem(item, index)}
                </div>
            ))}
        </div>
    );
};
export default FlatList;
