import React from 'react';
import MyAccountTemplate from '../../templates/MyAccountTemplate';
import View from '../../components/Base/View';
import Paragraph from '../../components/Base/Headings/Paragraph';

const ProfilePage = () => {
    return (
        <MyAccountTemplate>
            <View>
                <Paragraph size={'L'}>ProfilePage</Paragraph>
            </View>
        </MyAccountTemplate>
    );
};
export default ProfilePage;
