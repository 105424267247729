import React from 'react';
import View from '../Base/View';
import useCombinedClassName from '../../hooks/useCombinedClassName';
import styles from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { PagePath } from '../../models/enums/PagePath';
import useNavigation from '../../hooks/useNavigation';
import { useSettings } from '../../context/SettingsContext';
import FlatList from '../Base/Misc/FlatList';
import Paragraph from '../Base/Headings/Paragraph';
import NavItem from '../../models/NavItem';
import Image from '../Base/Misc/Image';
import Subtitle from '../Base/Headings/Subtitle';
import Separator from '../Separator';

interface SidebarMenuProps {
    isOpen: boolean;
    toggleSidebar: () => void;
}

const SidebarMenu = (props: SidebarMenuProps) => {
    const { isOpen, toggleSidebar } = props;
    const navigation = useNavigation();
    const { logo, navItems } = useSettings();

    const combinedClassName = useCombinedClassName(styles.sidebar, isOpen ? styles.sidebarOpen : '');

    const renderTopItems = (item: NavItem) => {
        const onClick = () => {
            toggleSidebar();
            navigation.navigateToUrl(item.url);
        };

        return (
            <View className={styles.navItem} id={item.slug} onClick={onClick}>
                <Paragraph size={'M'}>{item.name}</Paragraph>
            </View>
        );
    };

    const renderBottomItems = (item: NavItem) => {
        const onClick = () => {
            toggleSidebar();
            navigation.navigateToUrl(item.url);
        };

        return (
            <View className={styles.navItem} id={item.slug} onClick={onClick}>
                <Paragraph size={'L'} isBold>
                    {item.name}
                </Paragraph>
            </View>
        );
    };

    const onLogoClick = () => {
        toggleSidebar();
        navigation.navigateToHome();
    };

    const onLoginClick = () => {
        toggleSidebar();
        navigation.navigateToLogin();
    };

    const onRegisterClick = () => {
        toggleSidebar();
        navigation.navigateToRegister();
    };

    return (
        <View className={combinedClassName}>
            {/* Logo Section */}
            <View flexDirection={'row'} style={{ padding: 20 }} alignItems={'center'}>
                <View flex={1} justifyContent={'flex-start'}>
                    <View onClick={onLogoClick}>{logo && <Image className={styles.logo} url={logo} />}</View>
                </View>
                <View flex={1} justifyContent={'flex-end'}>
                    <View className={styles.closeButton} onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faX} />
                    </View>
                </View>
            </View>

            <Separator />

            {/* Categories Section */}
            <View flexDirection={'column'}>
                <View className={styles.sidebarContent}>
                    <Subtitle className={styles.sectionTitle} size={'L'}>
                        Esplora
                    </Subtitle>
                    {navItems && <FlatList data={navItems?.bottom} renderItem={renderTopItems} keyExtractor={(item, index) => index.toString()} elements={navItems.bottom.length} />}
                </View>
            </View>

            {/* Pages Section */}
            <View flexDirection={'column'} marginBottom={20}>
                <View className={styles.sidebarContent}>
                    {navItems && <FlatList data={navItems?.top} renderItem={renderBottomItems} keyExtractor={(item, index) => index.toString()} elements={navItems.bottom.length} />}
                </View>
            </View>

            {/* Login/Register Section */}
            <View className={styles.loginRegisterSection} flexDirection={'row'} alignItems={'flex-end'}>
                <View flex={1} className={styles.loginRegisterItem} onClick={onLoginClick}>
                    <Paragraph size={'M'} isBold>
                        Login
                    </Paragraph>
                </View>
                <View flex={1} className={styles.loginRegisterItem} onClick={onRegisterClick}>
                    <Paragraph size={'M'} isBold>
                        Registrati
                    </Paragraph>
                </View>
            </View>
        </View>
    );
};
export default SidebarMenu;
