import React, { useMemo } from 'react';
import View from '../../../../components/Base/View';
import Separator from '../../../../components/Separator';
import Subtitle from '../../../../components/Base/Headings/Subtitle';
import Paragraph from '../../../../components/Base/Headings/Paragraph';
import Colors from '../../../../toolkit/Colors';
import styles from './style.module.css';
import useContentWidth from '../../../../hooks/useContentWidth';

const HistoricalData = () => {
    const contentWidth = useContentWidth('content');

    const historicalData = [
        { title: '143€ - 364€', subtitle: 'Intervallo su 12 mesi' },
        { title: '166€ - 192€', subtitle: 'Intervallo assoluto' },
        { title: '7%', subtitle: 'Volatilità' },
        { title: '8.524', subtitle: 'Numero di vendite' },
        { title: '2%', subtitle: 'Markup' },
        { title: '186€', subtitle: 'Prezzo medio di vendita' },
    ];

    const renderDesktopSection = useMemo(() => {
        return (
            <>
                <View gap={16} marginBottom={16}>
                    {historicalData.slice(0, 3).map((item) => {
                        return (
                            <View key={item.title.toLowerCase()} flex={1} flexDirection={'column'} className={styles.historicalDataContainer}>
                                <Paragraph size={'XL'} isBold style={{ marginBottom: 2 }}>
                                    {item.title}
                                </Paragraph>
                                <Paragraph size={'S'} color={Colors.BLACK25}>
                                    {item.subtitle}
                                </Paragraph>
                            </View>
                        );
                    })}
                </View>
                <View gap={16}>
                    {historicalData.slice(3, 6).map((item) => {
                        return (
                            <View key={item.title.toLowerCase()} flex={1} flexDirection={'column'} className={styles.historicalDataContainer}>
                                <Paragraph size={'XL'} isBold style={{ marginBottom: 2 }}>
                                    {item.title}
                                </Paragraph>
                                <Paragraph size={'S'} color={Colors.BLACK25}>
                                    {item.subtitle}
                                </Paragraph>
                            </View>
                        );
                    })}
                </View>
            </>
        );
    }, []);

    const renderMobileSection = useMemo(() => {
        return (
            <View flexDirection={'column'}>
                {historicalData.map((item, index) => {
                    const isLast = index === historicalData.length - 1;

                    return (
                        <View flexDirection={'row'} className={isLast ? styles.last : styles.historicalDataContainerResponsive}>
                            <View flex={1}>
                                <Paragraph size={'M'}>{item.subtitle}</Paragraph>
                            </View>
                            <View>
                                <Paragraph size={'M'} color={Colors.BLACK25}>
                                    {item.title}
                                </Paragraph>
                            </View>
                        </View>
                    );
                })}
            </View>
        );
    }, []);

    return (
        <View id={'historical-data'} flexDirection={'column'} paddingBottom={32} isBoxed>
            <Separator marginBottom={16} />
            <View marginBottom={16}>
                <Subtitle size={'L'}>Dati storici 12 mesi</Subtitle>
            </View>
            {contentWidth && contentWidth >= 768 && renderDesktopSection}
            {contentWidth && contentWidth < 768 && renderMobileSection}
        </View>
    );
};
export default HistoricalData;
