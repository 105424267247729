import React, { useCallback, useState } from 'react';
import styles from './style.module.css';
import View from '../../components/Base/View';
import Paragraph from '../../components/Base/Headings/Paragraph';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import Colors from '../../toolkit/Colors';
import Separator from '../../components/Separator';
import TextInput from '../../components/Base/TextInput';
import Subtitle from '../../components/Base/Headings/Subtitle';
import useNavigation from '../../hooks/useNavigation';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';
import User from '../../models/User';
import { AlertType } from '../../models/enums/AlertType';
import AuthManager from '../../manager/AuthManager';
import LoginParams from '../../models/requests/LoginParams';

const LoginPage = () => {
    const navigation = useNavigation();
    const { showAlert } = useAlert();
    const { setUser } = useUser();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const checkData = useCallback(async () => {
        if (!email || !password) {
            showAlert('Attenzione', 'Inserisci tutti i campi obbligatori', AlertType.error);
        } else {
            await loginUser();
        }
    }, [email, password]);

    const loginUser = async () => {
        try {
            const params: LoginParams = {
                email,
                password,
            };
            let response = await AuthManager.login(params);
            const user: User = response.data;
            setUser(user);
            showAlert('Login effettuato', response.message, AlertType.success);
            navigation.navigateToProfile();
        } catch (e: any) {
            const errorMessage = e.response ? e.response.data.message : e.message;
            showAlert('Attenzione', errorMessage, AlertType.error);
        }
    };

    return (
        <View flexDirection={'row'} isBoxed alignItems={'flex-start'} justifyContent={'center'} className={styles.container}>
            <View className={styles.formContainer} flexDirection={'column'} gap={16}>
                <HeaderSection navigation={navigation} />
                <FormSection email={email} setEmail={setEmail} password={password} setPassword={setPassword} checkData={checkData} />
                <FooterSection navigation={navigation} />
            </View>
        </View>
    );
};
export default LoginPage;

interface HeaderSectionProps {
    navigation: ReturnType<typeof useNavigation>;
}
const HeaderSection = (props: HeaderSectionProps) => {
    return (
        <View marginBottom={16} gap={4}>
            <View className={styles.registerContainer} onClick={() => props.navigation.navigateToRegister()}>
                <Paragraph size={'S'}>Registrati</Paragraph>
            </View>
            <View className={styles.loginContainer} onClick={() => props.navigation.navigateToLogin()}>
                <Paragraph size={'S'} isBold>
                    Login
                </Paragraph>
            </View>
        </View>
    );
};

interface FormSectionProps {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    checkData: () => void;
}
const FormSection = (props: FormSectionProps) => {
    return (
        <View flexDirection={'column'}>
            <View marginBottom={16}>
                <Subtitle size={'L'}>Login</Subtitle>
            </View>
            <View flexDirection={'column'} gap={8} marginBottom={16}>
                <TextInput placeholder={'Email'} type={'email'} required onChange={(value) => props.setEmail(value)} />
                <View flexDirection={'column'}>
                    <TextInput placeholder={'Password'} type={'password'} required onChange={(value) => props.setPassword(value)} />
                    <View paddingTop={8} justifyContent={'flex-end'}>
                        <View onClick={() => null}>
                            <Paragraph size={'XS'}>Hai dimenticato la password?</Paragraph>
                        </View>
                    </View>
                </View>
            </View>
            <View flex={1} flexDirection={'row'} marginBottom={8}>
                <ButtonPrimary title={'Login'} textColor={'white'} backgroundColor={Colors.BLACK75} onPress={props.checkData} />
            </View>
            <View marginBottom={8}>
                <Paragraph size={'XS'} style={{ textAlign: 'justify' }} color={Colors.BLACK50}>
                    Facendo il login, accetti i Termini del Servizio e l'Informativa sulla Privacy.
                </Paragraph>
            </View>
        </View>
    );
};

interface FooterSectionProps {
    navigation: ReturnType<typeof useNavigation>;
}
const FooterSection = (props: FooterSectionProps) => {
    return (
        <>
            <Separator marginTop={16} />
            <View flexDirection={'row'} justifyContent={'center'}>
                <Paragraph size={'S'} style={{ marginRight: 4 }}>
                    Hai bisogno di un account?
                </Paragraph>
                <View onClick={() => props.navigation.navigateToRegister()}>
                    <Paragraph size={'S'} isBold>
                        Registrati
                    </Paragraph>
                </View>
            </View>
        </>
    );
};
