// AlertContext.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect, useMemo } from 'react';
import styles from './style.module.css';
import useCombinedClassName from '../hooks/useCombinedClassName';
import View from '../components/Base/View';
import Paragraph from '../components/Base/Headings/Paragraph';
import Subtitle from '../components/Base/Headings/Subtitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { AlertType } from '../models/enums/AlertType';
import Alert from '../components/Alert';

interface AlertContextType {
    showAlert: (title: string, message: string, type: AlertType) => void;
    hideAlert: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider = (props: AlertProviderProps) => {
    const { children } = props;
    const [type, setType] = useState<AlertType | null>(null);
    const [title, setTitle] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [visible, setVisible] = useState(false);

    const showAlert = useCallback((title: string, message: string, type: AlertType) => {
        setType(type);
        setTitle(title);
        setMessage(message);
        setVisible(true);
        setTimeout(() => {
            setVisible(false);
        }, 3000);
    }, []);

    const hideAlert = useCallback(() => {
        setVisible(false);
    }, []);

    useEffect(() => {
        if (!visible && message) {
            const timer = setTimeout(() => {
                setTitle(null);
                setMessage(null);
                setType(null);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [visible, message]);

    return (
        <AlertContext.Provider value={{ showAlert, hideAlert }}>
            {children}
            <Alert title={title} message={message} type={type} visible={visible} onClose={hideAlert} />
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};
