import React from 'react';
import styles from './style.module.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Price',
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56],
            fill: true,
            backgroundColor: 'rgb(28,201,111,0.2)',
            borderColor: 'rgb(28,201,111)',
            borderWidth: 2,
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            display: true,
            grid: {
                display: false,
            },
        },
        y: {
            min: 0,
            ticks: {
                display: true,
                callback: (value: number | string) => {
                    return `${value}€`;
                },
            },
            grid: {
                display: true,
            },
        },
    },
};

const LineChart: React.FC = () => {
    return <Line className={styles.canvas} data={data} options={options} />;
};

export default LineChart;
