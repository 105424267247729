import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Article from '../../models/Article';
import ApiManager from '../../manager/ApiManager';
import Paragraph from '../../components/Base/Headings/Paragraph';
import Brand from '../../models/Brand';
import FlatList from '../../components/Base/Misc/FlatList';
import CardArticleSmall from '../../components/Cells/CardArticleSmall';
import FilterItem from '../../models/FilterItem';
import View from '../../components/Base/View';
import Breadcrumb from '../../models/Breadcrumb';
import useChunkArray from '../../hooks/useChunkArray';
import useNavigation from '../../hooks/useNavigation';
import ArticlesTemplate from '../../templates/ArticlesTemplate';
import { useFilterArticlesByCategories } from '../../hooks/useFilterArticlesByBrand';

const BrandPage = () => {
    const { slug } = useParams<{ slug: string }>();
    const [brand, setBrand] = useState<Brand>();
    const [articles, setArticles] = useState<Article[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
    const [sidebarItems, setSidebarItems] = useState<FilterItem[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const navigation = useNavigation();

    /*
     * API: Fetch Data
     * */
    const fetchData = async () => {
        if (slug) {
            setIsLoading(true);
            try {
                let response = await ApiManager.getBrandArticles(slug);
                setBrand(response.brand);
                setArticles(response.articles);
                setBreadcrumbs(response.breadcrumbs);
                setSidebarItems(response.filters);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                navigate('*');
            }
        }
    };
    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [slug]);

    /*
     * FlatList Methods
     * */
    const renderArticle = (item: Article, index: number) => {
        return <CardArticleSmall article={item} onPress={() => navigation.navigateToArticle(item.slug)} />;
    };
    const keyExtractor = (item: { id: number }, index: number) => {
        return item.id.toString();
    };

    /*
     * Filter Methods
     * */
    const filters = useMemo(() => {
        const categoriesSet = new Set<string>();
        const categories: FilterItem[] = [];

        articles.forEach((article) => {
            if (article.category && !categoriesSet.has(article.category.id.toString())) {
                categoriesSet.add(article.category.id.toString());
                categories.push(article.category);
            }
        });

        categories.sort((a, b) => a.name.localeCompare(b.name));
        return categories;
    }, [articles]);
    const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);
    const filteredArticles = useFilterArticlesByCategories(articles, activeFilters);
    const articleChunks = useChunkArray(filteredArticles.length === 0 ? articles : filteredArticles, 4);
    const onFilterClick = (items: FilterItem[]) => {
        setActiveFilters(items);
    };

    /*
     * Template Props
     * */
    const filterProps = {
        items: filters,
        onFilterClick,
    };
    const sidebarProps = {
        currentId: brand?.id,
        items: sidebarItems,
    };
    const headerProps = {
        title: brand?.name,
        subtitle: brand?.bio,
    };

    return (
        <ArticlesTemplate header={headerProps} filters={filterProps} sidebar={sidebarProps} breadcrumbs={breadcrumbs}>
            <View id={'brand-articles'} flexDirection={'column'} gap={16}>
                {articleChunks.length > 0 &&
                    articleChunks.map((chunk, index) => <FlatList key={index} horizontal data={chunk} renderItem={renderArticle} keyExtractor={keyExtractor} elements={4} minElements={2} gap={16} />)}
                {articleChunks.length === 0 && <Paragraph size={'L'}>Nessun risultato</Paragraph>}
            </View>
        </ArticlesTemplate>
    );
};
export default BrandPage;
