import React from 'react';
import View from '../../components/Base/View';
import Paragraph from '../../components/Base/Headings/Paragraph';
import Subtitle from '../../components/Base/Headings/Subtitle';
import { useUser } from '../../context/UserContext';
import AuthManager from '../../manager/AuthManager';
import { AlertType } from '../../models/enums/AlertType';
import { useAlert } from '../../context/AlertContext';
import useNavigation from '../../hooks/useNavigation';
import styles from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faGear, faMoneyBill, faRightFromBracket, faUser, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { PagePath } from '../../models/enums/PagePath';
import Colors from '../../toolkit/Colors';

interface MyAccountTemplateProps {
    children: React.ReactNode;
}

interface SidebarMenuItemProps {
    name: string;
    text: string | null;
    slug: string;
    url: string;
    icon: IconDefinition;
}

const MyAccountTemplate = (props: MyAccountTemplateProps) => {
    const { children } = props;
    const navigation = useNavigation();
    const { user, clearUser } = useUser();
    const { showAlert } = useAlert();

    const menuItems: SidebarMenuItemProps[] = [
        { slug: 'profile', name: 'Profilo', text: 'Spedizione, email, password', url: '/profile', icon: faUser },
        { slug: 'buy', name: 'Acquisti', text: 'Offerte attive, In corso, Ordini completati', url: '/buy', icon: faCartShopping },
        {
            slug: 'sell',
            name: 'Vendite',
            text: 'Proposte attive, vendite e profilo del venditore',
            url: '/sell',
            icon: faMoneyBill,
        },
        { slug: 'settings', name: 'Impostazioni', text: 'Sicurezza e notifiche', url: '/settings', icon: faGear },
        { slug: 'logout', name: 'Logout', text: null, url: '/logout', icon: faRightFromBracket },
    ];

    const logout = async () => {
        try {
            let response = await AuthManager.logout();
            navigation.navigateToHome();
            clearUser();
            showAlert('Success', response.message, AlertType.success);
        } catch (e) {
            console.log(e);
        }
    };

    const SidebarMenuItem = (props: { item: SidebarMenuItemProps }) => {
        const onClick = async () => {
            if (props.item.url === PagePath.LOGOUT) {
                await logout();
            } else {
                navigation.navigateToUrl(props.item.url);
            }
        };

        return (
            <View flexDirection={'row'} alignItems={'center'} onClick={onClick}>
                <View className={styles.iconContainer}>
                    <FontAwesomeIcon icon={props.item.icon} className={styles.icon} />
                </View>
                <View flexDirection={'column'}>
                    <Paragraph size={'M'} isBold>
                        {props.item.name}
                    </Paragraph>
                    {props.item.text && (
                        <Paragraph size={'XS'} color={Colors.BLACK75}>
                            {props.item.text}
                        </Paragraph>
                    )}
                </View>
            </View>
        );
    };

    return (
        <View className={styles.container} flexDirection={'row'} responsive>
            <View className={styles.sidebarContent} flexDirection={'column'}>
                <View marginBottom={40}>
                    <Subtitle size={'L'}>
                        {user?.first_name} {user?.last_name}
                    </Subtitle>
                </View>

                <View flexDirection={'column'} gap={40}>
                    {menuItems.map((item) => {
                        return <SidebarMenuItem key={item.slug} item={item} />;
                    })}
                </View>
            </View>
            <View flexDirection={'row'}>{children}</View>
        </View>
    );
};
export default MyAccountTemplate;
