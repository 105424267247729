import React from 'react';
import styles from './style.module.css';
import View from '../Base/View';
import Paragraph from '../Base/Headings/Paragraph';
import { NavLink } from 'react-router-dom';
import Breadcrumb from '../../models/Breadcrumb';
import Colors from '../../toolkit/Colors';

interface BreadcrumbsProps {
    elements: Breadcrumb[];
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const { elements } = props;
    return (
        <View flexDirection={'row'} hideOn={'smartphone'}>
            {elements.map((element, index) => {
                const isLast = index + 1 === elements.length;
                return (
                    <View key={index}>
                        <NavLink to={element.url} className={isLast ? styles.itemLast : undefined}>
                            <Paragraph size={'S'} color={isLast ? Colors.BLACK90 : Colors.BLACK75} isBold={isLast}>
                                {element.title}
                            </Paragraph>
                        </NavLink>
                        {!isLast && (
                            <Paragraph size={'S'} className={styles.separator}>
                                /
                            </Paragraph>
                        )}
                    </View>
                );
            })}
        </View>
    );
};
export default Breadcrumbs;
