import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './style.module.css';
import View from '../../components/Base/View';
import Paragraph from '../../components/Base/Headings/Paragraph';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import Colors from '../../toolkit/Colors';
import Separator from '../../components/Separator';
import TextInput from '../../components/Base/TextInput';
import Subtitle from '../../components/Base/Headings/Subtitle';
import useNavigation from '../../hooks/useNavigation';
import Checkbox from '../../components/Checkbox';
import { useAlert } from '../../context/AlertContext';
import { AlertType } from '../../models/enums/AlertType';
import RegisterParams from '../../models/requests/RegisterParams';
import AuthManager from '../../manager/AuthManager';

const TC_ACCEPTANCE_TEXT =
    'Iscrivendoti, dichiari di aver letto e accettato i nostri Termini e Condizioni. Per informazioni sulle modalità di trattamento dei tuoi dati, consulta la nostra Informativa sulla privacy.';
const NEWSLETTER_TEXT =
    "Ti invieremo regolarmente email con informazioni e offerte. Puoi annullare l'iscrizione alle email in qualsiasi momento tramite il link contenuto nelle email o nelle impostazioni del tuo account.";

const RegisterPage = () => {
    const navigation = useNavigation();
    const checkboxRef = useRef<{ toggleCheck: () => void }>(null);
    const { showAlert } = useAlert();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [acceptance, setAcceptance] = useState(false);

    const checkData = useCallback(async () => {
        if (!firstName || !lastName || !email || !password) {
            showAlert('Attenzione', 'Inserisci tutti i campi obbligatori.', AlertType.error);
        } else {
            await registerUser();
        }
    }, [firstName, lastName, email, password]);

    const registerUser = async () => {
        try {
            //TODO: Passare via API anche se l'acceptance newsletter
            const params: RegisterParams = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                password_confirmation: password,
            };
            let response = await AuthManager.register(params);
            showAlert('Registrazione effettuata!', response.message, AlertType.success);
            navigation.navigateToLogin();
        } catch (e: any) {
            const errorMessage = e.response ? e.response.data.message : e.message;
            showAlert('Attenzione', errorMessage, AlertType.error);
        }
    };

    return (
        <View flexDirection={'row'} isBoxed alignItems={'flex-start'} justifyContent={'center'} className={styles.container}>
            <View className={styles.formContainer} flexDirection={'column'} gap={16}>
                <HeaderSection navigation={navigation} />
                <FormSection
                    firstName={firstName}
                    setFirstName={setFirstName}
                    acceptance={acceptance}
                    setAcceptance={setAcceptance}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    checkData={checkData}
                    checkboxRef={checkboxRef}
                />
                <FooterSection navigation={navigation} />
            </View>
        </View>
    );
};
export default RegisterPage;

interface HeaderSectionProps {
    navigation: ReturnType<typeof useNavigation>;
}

const HeaderSection = (props: HeaderSectionProps) => {
    return (
        <View marginBottom={16} gap={4}>
            <View className={styles.registerContainer} onClick={() => props.navigation.navigateToRegister()}>
                <Paragraph size={'S'} isBold>
                    Registrati
                </Paragraph>
            </View>
            <View className={styles.loginContainer} onClick={() => props.navigation.navigateToLogin()}>
                <Paragraph size={'S'}>Login</Paragraph>
            </View>
        </View>
    );
};

interface FormSectionProps {
    firstName: string;
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
    lastName: string;
    setLastName: React.Dispatch<React.SetStateAction<string>>;
    acceptance: boolean;
    setAcceptance: React.Dispatch<React.SetStateAction<boolean>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    checkData: () => void;
    checkboxRef: React.RefObject<{ toggleCheck: () => void }>;
}

const FormSection = (props: FormSectionProps) => {
    return (
        <View flexDirection={'column'}>
            <View marginBottom={16}>
                <Subtitle size={'L'}>Registrati</Subtitle>
            </View>
            <View flexDirection={'column'} gap={8} marginBottom={16}>
                <TextInput placeholder={'Nome'} required onChange={(value) => props.setFirstName(value)} />
                <TextInput placeholder={'Cognome'} required onChange={(value) => props.setLastName(value)} />
                <TextInput placeholder={'Email'} type={'email'} required onChange={(value) => props.setEmail(value)} />
                <TextInput placeholder={'Password'} type={'password'} required onChange={(value) => props.setPassword(value)} />
            </View>
            <View flex={1} flexDirection={'row'} marginBottom={8}>
                <ButtonPrimary title={'Registrati'} textColor={'white'} backgroundColor={Colors.BLACK75} onPress={props.checkData} />
            </View>
            <View marginBottom={8}>
                <Paragraph size={'XS'} style={{ textAlign: 'justify' }} color={Colors.BLACK50}>
                    {TC_ACCEPTANCE_TEXT}
                </Paragraph>
            </View>
            <View gap={8} flexDirection={'row'}>
                <View>
                    <Checkbox ref={props.checkboxRef} onChange={(value) => props.setAcceptance(value)} />
                </View>
                <View flex={1} onClick={() => props.checkboxRef.current?.toggleCheck()}>
                    <Paragraph size={'XS'} style={{ textAlign: 'justify' }} color={Colors.BLACK25}>
                        {NEWSLETTER_TEXT}
                    </Paragraph>
                </View>
            </View>
        </View>
    );
};

interface FooterSectionProps {
    navigation: ReturnType<typeof useNavigation>;
}

const FooterSection = (props: FooterSectionProps) => {
    return (
        <>
            <Separator marginTop={16} />
            <View flexDirection={'row'} justifyContent={'center'}>
                <Paragraph size={'S'} style={{ marginRight: 4 }}>
                    Hai già un account?
                </Paragraph>
                <View onClick={() => props.navigation.navigateToLogin()}>
                    <Paragraph size={'S'} isBold>
                        Login
                    </Paragraph>
                </View>
            </View>
        </>
    );
};
