import React from 'react';
import styles from './style.module.css';
import useCombinedClassName from '../../hooks/useCombinedClassName';
import { useLocation } from 'react-router-dom';
import { PagePath } from '../../models/enums/PagePath';

const Footer = () => {
    const combinedClassName = useCombinedClassName(styles.footer);
    const { pathname } = useLocation();

    if (pathname === PagePath.LOGIN || pathname === PagePath.REGISTER) {
        return null;
    }

    return (
        <footer id={'footer'} className={combinedClassName}>
            <div className={styles.inner}>
                <p style={{ color: 'white' }}>Footer</p>
            </div>
        </footer>
    );
};
export default Footer;
