import React from 'react';
import styles from './style.module.css';
import View from '../Base/View';
import Paragraph from '../Base/Headings/Paragraph';

const LoadingView = () => {
    return (
        <View className={styles.container}>
            <Paragraph size={'L'}>Loading...</Paragraph>
        </View>
    );
};
export default LoadingView;
