import {useMemo} from "react";

function useChunkArray(array: any[], chunkSize: number) {

    return useMemo(() => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }, [array, chunkSize]);

}

export default useChunkArray;
