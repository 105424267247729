import React from 'react';
import Separator from '../../../../components/Separator';
import View from '../../../../components/Base/View';
import Subtitle from '../../../../components/Base/Headings/Subtitle';
import FlatList from '../../../../components/Base/Misc/FlatList';
import Article from '../../../../models/Article';
import CardArticleSmall from '../../../../components/Cells/CardArticleSmall';
import useNavigation from '../../../../hooks/useNavigation';

interface RelatedArticleProps {
    relatedArticles: Article[];
}

const RelatedArticles = (props: RelatedArticleProps) => {
    const { relatedArticles } = props;
    const navigation = useNavigation();

    const renderArticle = (item: Article, index: number) => {
        return <CardArticleSmall article={item} onPress={() => navigation.navigateToArticle(item.slug)} />;
    };

    const keyExtractor = (item: { id: number }, index: number) => {
        return item.id.toString();
    };

    return (
        <>
            {relatedArticles.length === 0 && null}
            {relatedArticles.length > 0 && (
                <View id={'related-articles'} marginBottom={32} flexDirection={'column'} isBoxed>
                    <Separator marginBottom={16} />
                    <View flexDirection={'column'} isSection>
                        <View marginBottom={16}>
                            <Subtitle size={'L'}>Articoli simili</Subtitle>
                        </View>
                        <View>
                            <FlatList horizontal data={relatedArticles} renderItem={renderArticle} keyExtractor={keyExtractor} gap={16} elements={6} minElements={2} />
                        </View>
                    </View>
                </View>
            )}
        </>
    );
};
export default RelatedArticles;
