import React from 'react';
import useCombinedStyle from '../../../../hooks/useCombinedStyle';
import useCombinedClassName from '../../../../hooks/useCombinedClassName';
import styles from './style.module.css';

interface ImageProps {
    url: string;
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    aspectRatio?: React.CSSProperties['aspectRatio'];
    objectFit?: React.CSSProperties['objectFit'];
    style?: React.CSSProperties;
    className?: string;
}

const Image = (props: ImageProps) => {
    const { url, style, width, height, aspectRatio, objectFit, className } = props;
    const combinedClassName = useCombinedClassName(className, styles.image);
    const combinedStyle = useCombinedStyle({ width, height, aspectRatio, objectFit }, style);

    return <img className={combinedClassName} alt={''} src={url} style={combinedStyle} />;
};
export default Image;
