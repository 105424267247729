import axios, {AxiosRequestConfig} from 'axios';
import User from "../models/User";

const apiClient = axios.create();

apiClient.interceptors.request.use(async (config: AxiosRequestConfig) => {

    let headers: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const storedUser = localStorage.getItem('user');
    const user: User | null = storedUser ? JSON.parse(storedUser) : null;
    if (user !== null) {
        headers.Authorization = 'Bearer ' + user.access_token;
    }

    return {
        ...config,
        headers,
    };
});

export default apiClient;
