import RegisterParams from '../models/requests/RegisterParams';
import apiClient from '../services/config';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REGISTER } from '../services/api';
import { RequestMethod } from '../models/enums/RequestMethod';
import RegisterData from '../models/responses/RegisterData';
import LoginParams from '../models/requests/LoginParams';
import LoginData from '../models/responses/LoginData';
import LogoutData from '../models/responses/LogoutData';

class AuthManager {
    static register = async (params: RegisterParams) => {
        try {
            let response = await apiClient(AUTH_REGISTER, {
                method: RequestMethod.POST,
                params,
            });
            return response.data as RegisterData;
        } catch (e) {
            console.log('authRegister error', e);
            throw e;
        }
    };

    static login = async (params: LoginParams) => {
        try {
            let response = await apiClient(AUTH_LOGIN, {
                method: RequestMethod.POST,
                params,
            });
            return response.data as LoginData;
        } catch (e) {
            console.log('authLogin error', e);
            throw e;
        }
    };

    static logout = async () => {
        try {
            let response = await apiClient(AUTH_LOGOUT, {
                method: RequestMethod.POST,
            });
            return response.data as LogoutData;
        } catch (e) {
            console.log('authLogout error', e);
            throw e;
        }
    };
}
export default AuthManager;
