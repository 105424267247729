import React, { HTMLInputTypeAttribute, useState } from 'react';
import styles from './style.module.css';
import View from '../View';
import Paragraph from '../Headings/Paragraph';

interface TextInputProps {
    placeholder: string;
    required?: boolean;
    type?: HTMLInputTypeAttribute;
    onChange?: (value: string) => void;
}

const TextInput = (props: TextInputProps) => {
    const { placeholder, onChange, type = 'text', required = false } = props;
    const [value, setValue] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <View flexDirection={'column'}>
            <View className={styles.container} flexDirection={'column'} justifyContent={'center'}>
                {placeholder && value.length > 0 && (
                    <View className={styles.labelContainer}>
                        <Paragraph size={'XS'} className={styles.label}>
                            {placeholder}
                            {required ? '*' : null}
                        </Paragraph>
                    </View>
                )}

                <View>
                    <input className={styles.input} placeholder={`${placeholder}${required ? '*' : ''}`} value={value} onChange={handleInputChange} type={type} />
                </View>
            </View>
        </View>
    );
};
export default TextInput;
