import React, {useEffect, useMemo} from "react";
import useCombinedClassName from "../../../../hooks/useCombinedClassName";
import styles from './paragraph.module.css';

interface ParagraphProps {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: React.CSSProperties['color'];
    size: 'XS' | 'S' | 'M' | 'L' | 'XL';
    isBold?: boolean;
    maxLines?: number;
}

const Paragraph = (props: ParagraphProps) => {

    const {children, className, style, color, size, isBold, maxLines} = props;

    const fontSizeClass = useMemo(() => {
        const classMap = {
            'XS': styles.fontXs,
            'S': styles.fontSm,
            'M': styles.fontMd,
            'L': styles.fontLg,
            'XL': styles.fontXl
        };
        return classMap[size] || styles.fontMd;
    }, [size]);

    const combinedClass = useCombinedClassName(styles.paragraph, isBold ? styles.isBold : '', className, fontSizeClass, maxLines ? styles.clamp : '');
    const combinedStyle = {...style, color, WebkitLineClamp: maxLines};

    useEffect(() => {
    }, [size]);

    return (
        <p className={combinedClass} style={combinedStyle}>
            {children}
        </p>
    );
};
export default Paragraph;
