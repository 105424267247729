import ENV from '../.env.json';

const BASE_URL = ENV.BASE_URL;
const AUTH_URL = BASE_URL + '/auth';

export const AUTH_REGISTER = AUTH_URL + '/register';
export const AUTH_LOGIN = AUTH_URL + '/login';
export const AUTH_LOGOUT = AUTH_URL + '/logout';

export const GET_SETTINGS = BASE_URL + '/settings/all';

export const GET_HOME_PAGE_DATA = BASE_URL + '/home/all';
export const GET_BRAND_ARTICLES = (brand_slug: string) => BASE_URL + `/brand/` + brand_slug;
export const GET_CATEGORY_ARTICLES = (category_slug: string) => BASE_URL + `/category/` + category_slug;
export const GET_ARTICLE_DATA = (article_slug: string) => BASE_URL + `/article/` + article_slug;
