import React from 'react';
import View from '../../components/Base/View';
import Title from '../../components/Base/Headings/Title';
import Colors from '../../toolkit/Colors';
import Paragraph from '../../components/Base/Headings/Paragraph';
import useContentWidth from '../../hooks/useContentWidth';
import FilterItem from '../../models/FilterItem';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';
import Breadcrumb from '../../models/Breadcrumb';
import Breadcrumbs from '../../components/Breadcrumbs';
import Filters from '../../components/Filters';

interface ArticlesTemplateProps {
    header: {
        title?: string;
        subtitle?: string;
    };
    sidebar: {
        currentId?: number;
        items: FilterItem[];
    };
    breadcrumbs: Breadcrumb[];
    filters: {
        items: FilterItem[];
        onFilterClick: (items: FilterItem[]) => void;
    };
    children: React.ReactNode;
}

const ArticlesTemplate = (props: ArticlesTemplateProps) => {
    const { children, sidebar, header, breadcrumbs, filters } = props;
    const contentWidth = useContentWidth('content');

    //TODO: Spostare nello style
    const titlePaddingTopBottom = contentWidth && contentWidth >= 768 ? 32 : 0;

    return (
        <View className={styles.mainContainer} flexDirection={'column'} flex={1} isBoxed>
            <Header titlePaddingTopBottom={titlePaddingTopBottom} title={header.title} subtitle={header.subtitle} />

            <View className={styles.content} flexDirection={'row'}>
                <Sidebar items={sidebar.items} currentId={sidebar.currentId} />

                <View flex={1} flexDirection={'column'}>
                    <BreadcrumbsSection breadcrumbs={breadcrumbs} />
                    <FiltersSection filters={filters.items} onFilterClick={(items) => filters.onFilterClick(items)} />
                    {children}
                </View>
            </View>
        </View>
    );
};
export default ArticlesTemplate;

interface HeaderProps {
    titlePaddingTopBottom: number;
    title: string | undefined;
    subtitle: string | undefined;
}

const Header = (props: HeaderProps) => {
    return (
        <View flex={1} paddingTop={props.titlePaddingTopBottom} paddingBottom={props.titlePaddingTopBottom} isSection>
            <View flex={1} flexDirection={'column'} className={styles.headerContainer}>
                <Title className={styles.headerTitle} size={'XL'} color={Colors.BLACK90}>
                    {props.title}
                </Title>
                <Paragraph size={'S'} color={Colors.BLACK25}>
                    {props.subtitle}
                </Paragraph>
            </View>
        </View>
    );
};

interface SidebarProps {
    items: FilterItem[];
    currentId?: number;
}
const Sidebar = (props: SidebarProps) => {
    return (
        <View className={styles.sidebarContainer}>
            {props.items.map((item: FilterItem) => {
                return (
                    <NavLink key={item.id.toString()} to={item.url}>
                        <Paragraph className={styles.sideBarElement} isBold={item.id === props.currentId} size={'L'}>
                            {item.name}
                        </Paragraph>
                    </NavLink>
                );
            })}
        </View>
    );
};

interface BreadcrumbsSectionProps {
    breadcrumbs: Breadcrumb[];
}

const BreadcrumbsSection = (props: BreadcrumbsSectionProps) => {
    return (
        <View marginBottom={16}>
            <Breadcrumbs elements={props.breadcrumbs} />
        </View>
    );
};

interface FiltersSectionProps {
    filters: any[];
    onFilterClick: (items: FilterItem[]) => void;
}

const FiltersSection = (props: FiltersSectionProps) => {
    return (
        <View gap={8} marginBottom={16} style={{ flexWrap: 'wrap' }}>
            <Filters elements={props.filters} onClick={(items) => props.onFilterClick(items)} />
        </View>
    );
};
