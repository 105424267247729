import React from 'react';
import styles from './style.module.css';
import Article from '../../../models/Article';
import useCombinedClassName from '../../../hooks/useCombinedClassName';
import Image from '../../Base/Misc/Image';
import Paragraph from '../../Base/Headings/Paragraph';
import Colors from '../../../toolkit/Colors';
import { NavLink } from 'react-router-dom';
import View from '../../Base/View';

interface CardArticleSmallProps {
    article: Article;
    onPress: () => void;
}

const CardArticleSmall = (props: CardArticleSmallProps) => {
    const { article, onPress } = props;
    const combinedClass = useCombinedClassName(styles.container);

    return (
        <View flexDirection={'column'} className={combinedClass} style={{ flexDirection: 'column' }} onClick={onPress}>
            <View className={styles.img}>
                <Image url={article.img} width={'100%'} aspectRatio={1.6} className={styles.img} objectFit={'contain'} />
            </View>
            <View flexDirection={'column'} className={styles.infoContainer}>
                <View flex={1} style={{ marginBottom: 8 }}>
                    <Paragraph size={'M'} maxLines={2} isBold color={Colors.BLACK90}>
                        {article.name}
                    </Paragraph>
                </View>
                <View flexDirection={'column'}>
                    <Paragraph size={'S'}>Prezzo</Paragraph>
                    <Paragraph isBold size={'L'} color={Colors.BLACK90}>
                        €{article.price}
                    </Paragraph>
                </View>
            </View>
        </View>
    );
};
export default CardArticleSmall;
