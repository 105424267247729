import { useLocation } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import './style.module.css';
import View from '../Base/View';
import Paragraph from '../Base/Headings/Paragraph';
import styles from './style.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleUser, faUser } from '@fortawesome/free-solid-svg-icons';
import SidebarMenu from '../SidebarMenu';
import useCombinedClassName from '../../hooks/useCombinedClassName';
import { PagePath } from '../../models/enums/PagePath';
import { useUser } from '../../context/UserContext';
import { useAlert } from '../../context/AlertContext';
import useNavigation from '../../hooks/useNavigation';
import Image from '../Base/Misc/Image';
import { useSettings } from '../../context/SettingsContext';

const NavigationBar = () => {
    const { user } = useUser();
    const { navItems, logo } = useSettings();
    const { pathname } = useLocation();
    const navigation = useNavigation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const NavItemElement = (props: { title: string; onPress: () => void; isAuth?: boolean }) => {
        const combinedClassName = useCombinedClassName(styles.navLinksItems, styles.loginRegisterItem);
        return (
            <View onClick={props.onPress} className={props.isAuth ? combinedClassName : styles.navLinksItems}>
                <Paragraph size={'M'} isBold>
                    {props.title}
                </Paragraph>
            </View>
        );
    };

    const renderTopItems = useMemo(() => {
        return (
            <View alignItems={'center'}>
                <View gap={16} flexDirection={'row'} alignItems={'center'} className={styles.rightMenu}>
                    {navItems?.top.map((item) => {
                        return <NavItemElement key={item.slug} title={item.name} onPress={() => navigation.navigateToUrl(item.url)} />;
                    })}

                    {!user && (
                        <>
                            <NavItemElement title={'Login'} onPress={navigation.navigateToLogin} isAuth />
                            <NavItemElement title={'Registrati'} onPress={navigation.navigateToRegister} isAuth />
                        </>
                    )}

                    {user && (
                        <View onClick={() => navigation.navigateToProfile()}>
                            <FontAwesomeIcon icon={faCircleUser} style={{ fontSize: 20 }} />
                        </View>
                    )}
                </View>
                <View onClick={toggleSidebar} className={styles.burgerContainer}>
                    <FontAwesomeIcon icon={faBars} />
                </View>
            </View>
        );
    }, [user, navItems]);

    const renderBottomItems = useMemo(() => {
        if (pathname !== PagePath.LOGIN && pathname !== PagePath.REGISTER) {
            return (
                <View gap={16} flexDirection={'row'} className={styles.bottomNavContainer} justifyContent={'center'} hideOn={'smartphone'}>
                    {navItems?.bottom.slice(0, 6).map((item) => {
                        return <NavItemElement key={item.slug} title={item.name} onPress={() => navigation.navigateToUrl(item.url)} />;
                    })}
                </View>
            );
        }
    }, [pathname, navItems]);

    const renderLogo = useMemo(() => {
        return <View onClick={navigation.navigateToHome}>{logo && <Image className={styles.logo} url={logo} />}</View>;
    }, [logo]);

    return (
        <nav>
            <View flexDirection={'column'}>
                <SidebarMenu isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <View flex={1} flexDirection={'row'} alignItems={'center'} className={styles.topNavContainer}>
                    <View flexDirection={'row'} alignItems={'center'}>
                        {renderLogo}
                    </View>
                    <View flex={1} justifyContent={'flex-end'} flexDirection={'row'}>
                        {renderTopItems}
                    </View>
                </View>
                {renderBottomItems}
            </View>
        </nav>
    );
};
export default NavigationBar;
