import React from 'react';
import Image from '../../Base/Misc/Image';
import News from '../../../models/News';
import styles from './style.module.css';
import Subtitle from '../../Base/Headings/Subtitle';
import Colors from '../../../toolkit/Colors';
import Paragraph from '../../Base/Headings/Paragraph';
import useCombinedClassName from '../../../hooks/useCombinedClassName';
import View from '../../Base/View';

interface CardNewsProps {
    news: News;
}

const CardNews = (props: CardNewsProps) => {
    const { news } = props;
    const combinedClass = useCombinedClassName(styles.container);

    return (
        <View flexDirection={'column'} className={combinedClass}>
            <View>
                <Image className={styles.img} url={news.img} aspectRatio={1.8} objectFit={'cover'} />
            </View>
            <View>
                <View flexDirection={'column'} className={styles.titleContainer}>
                    <Subtitle className={styles.title} maxLines={2} size={'L'} color={Colors.BLACK90}>
                        {news.title}
                    </Subtitle>
                    <Paragraph size={'S'}>{news.date}</Paragraph>
                </View>
            </View>
        </View>
    );
};
export default CardNews;
