import apiClient from '../services/config';
import { AUTH_LOGIN, AUTH_REGISTER, GET_ARTICLE_DATA, GET_BRAND_ARTICLES, GET_CATEGORY_ARTICLES, GET_HOME_PAGE_DATA } from '../services/api';
import { RequestMethod } from '../models/enums/RequestMethod';
import HomeData from '../models/responses/HomeData';

class ApiManager {
    static getHomePageData = async () => {
        try {
            let response = await apiClient(GET_HOME_PAGE_DATA, {
                method: RequestMethod.GET,
            });
            return response.data as HomeData;
        } catch (e) {
            console.log('getHomePageData error', e);
            throw e;
        }
    };

    static getBrandArticles = async (brand_slug: string) => {
        try {
            let response = await apiClient(GET_BRAND_ARTICLES(brand_slug), {
                method: RequestMethod.GET,
            });
            return response.data.data;
        } catch (e) {
            console.log('getArticlesBySlug error', e);
            throw e;
        }
    };

    static getCategoryArticles = async (category_slug: string) => {
        try {
            let response = await apiClient(GET_CATEGORY_ARTICLES(category_slug), {
                method: RequestMethod.GET,
            });
            return response.data.data;
        } catch (e) {
            console.log('getCategoryArticles error', e);
            throw e;
        }
    };

    static getArticleData = async (article_slug: string) => {
        try {
            let response = await apiClient(GET_ARTICLE_DATA(article_slug), {
                method: RequestMethod.GET,
            });
            return response.data.data;
        } catch (e) {
            console.log('getArticleData error', e);
            throw e;
        }
    };
}

export default ApiManager;
