import React, { useMemo } from 'react';
import styles from './style.module.css';
import View from '../../../../components/Base/View';
import Image from '../../../../components/Base/Misc/Image';
import Subtitle from '../../../../components/Base/Headings/Subtitle';
import Paragraph from '../../../../components/Base/Headings/Paragraph';
import Colors from '../../../../toolkit/Colors';
import Article from '../../../../models/Article';

interface ArticleDataProps {
    article?: Article;
}

const ArticleData = (props: ArticleDataProps) => {
    const { article } = props;

    const renderImage = useMemo(() => {
        return (
            <View flexDirection={'column'} style={{ width: '100%' }}>
                <View flexDirection={'column'}>
                    <Image url={article ? article?.img : ''} width={'100%'} />
                </View>
            </View>
        );
    }, [article]);
    const renderAttributes = useMemo(() => {
        if (article?.attributes && article.attributes.length > 0) {
            return (
                <>
                    <View marginBottom={8}>
                        <Subtitle size={'S'}>Dettagli</Subtitle>
                    </View>
                    <View flexDirection={'column'}>
                        {article?.attributes.map((item, index) => {
                            return (
                                <View key={item.name.toLowerCase()} flexDirection={'row'} gap={16} marginBottom={8} alignItems={'center'}>
                                    <View style={{ width: 100 }}>
                                        <Paragraph size={'S'} color={Colors.BLACK25}>
                                            {item.name}
                                        </Paragraph>
                                    </View>
                                    <View flex={1}>
                                        <Paragraph size={'S'}>{item.value}</Paragraph>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </>
            );
        } else {
            return null;
        }
    }, [article?.attributes]);
    const renderDescription = useMemo(() => {
        return (
            <>
                <View marginBottom={8} flexDirection={'row'}>
                    <Subtitle size={'L'}>Descrizione Prodotto</Subtitle>
                </View>
                <View flexDirection={'column'} marginBottom={32}>
                    <Paragraph size={'S'} style={{ textAlign: 'justify' }} color={Colors.BLACK25}>
                        {article?.description}
                    </Paragraph>
                </View>
            </>
        );
    }, [article]);

    return (
        <View id={'article-data'} isBoxed paddingTop={32} paddingBottom={32} isSection style={{ gap: 32 }} flexDirection={'column'}>
            <View flexDirection={'row'} flex={1} gap={32} responsive>
                {/* Article Image */}
                <View flexDirection={'row'} flex={1}>
                    {renderImage}
                </View>

                {/* Article Info */}
                <View flexDirection={'row'} flex={1}>
                    <View flexDirection={'column'}>
                        {renderDescription}
                        {renderAttributes}
                    </View>
                </View>
            </View>
        </View>
    );
};
export default ArticleData;
