import React, { createContext, useContext, useEffect, useState } from 'react';
import NavItem from '../models/NavItem';
import SettingsManager from '../manager/SettingsManager';

interface NavItemsProps {
    top: NavItem[];
    bottom: NavItem[];
}

interface SettingsContextProps {
    logo: string | undefined;
    navItems: NavItemsProps | undefined;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(undefined);

export const useSettings = (): SettingsContextProps => {
    const context = useContext(SettingsContext);
    if (!context) {
        throw new Error('useSettings must be used within a NavProvider');
    }
    return context;
};

interface SettingsProviderProps {
    children: React.ReactNode;
}

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
    const [navItems, setNavItems] = useState<NavItemsProps>();
    const [logo, setLogo] = useState<string>();

    useEffect(() => {
        (async () => {
            const response = await SettingsManager.getAll();
            setNavItems(response.data.nav);
            setLogo(response.data.logo);
        })();
    }, []);

    return <SettingsContext.Provider value={{ navItems, logo }}>{children}</SettingsContext.Provider>;
};
