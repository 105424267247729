import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import styles from './style.module.css';
import View from '../Base/View';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import useCombinedClassName from '../../hooks/useCombinedClassName';

interface CheckboxProps {
    onChange: (value: boolean) => void;
}

const Checkbox = forwardRef((props: CheckboxProps, ref) => {
    const [selected, setSelected] = useState(false);

    const toggleCheck = () => {
        setSelected((prevState) => !prevState);
    };

    useEffect(() => {
        props.onChange(selected);
    }, [selected]);

    const combinedClass = useCombinedClassName(styles.container, selected ? styles.selected : '');

    useImperativeHandle(ref, () => ({
        toggleCheck,
    }));

    return (
        <View onClick={toggleCheck}>
            <FontAwesomeIcon icon={selected ? faCheck : faSquare} className={combinedClass} />
        </View>
    );
});

export default Checkbox;
