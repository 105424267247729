import {useEffect, useState} from "react";

const useContentWidth = (elementId: string) => {
    const [contentWidth, setContentWidth] = useState<number | undefined>(undefined);

    const updateDivWidth = () => {
        const specificDiv = document.getElementById(elementId);
        if (specificDiv) {
            setContentWidth(specificDiv.getBoundingClientRect().width);
        }
    };

    useEffect(() => {
        updateDivWidth();
        window.addEventListener('resize', updateDivWidth);
        return () => {
            window.removeEventListener('resize', updateDivWidth);
        };
    }, [elementId]);

    return contentWidth;
};

export default useContentWidth;
