export enum PagePath {
    HOME = '/',
    LOGIN = '/login',
    REGISTER = '/register',
    ABOUT = '/about',
    NEWS = '/news',
    BRAND = '/brand',
    ARTICLE = '/article',
    CATEGORY = '/category',
    ARTICLES = '/articles',
    PROFILE = '/profile',
    SETTINGS = '/settings',
    LOGOUT = '/logout',
}
