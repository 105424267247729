import React, { useMemo } from 'react';
import useCombinedClassName from '../../../../hooks/useCombinedClassName';
import styles from './title.module.css';
import useCombinedStyle from '../../../../hooks/useCombinedStyle';
import Colors from '../../../../toolkit/Colors';

interface TitleProps {
    children: React.ReactNode;
    className?: string;
    size: 'XS' | 'S' | 'M' | 'L' | 'XL';
    style?: React.CSSProperties;
    color?: React.CSSProperties['color'];
}

const Title = (props: TitleProps) => {
    const { children, className, color, size, style } = props;

    const fontSizeClass = useMemo(() => {
        const classMap = {
            XS: styles.fontXs,
            S: styles.fontSm,
            M: styles.fontMd,
            L: styles.fontLg,
            XL: styles.fontXl,
        };
        return classMap[size] || styles.fontMd;
    }, [size]);

    const combinedClass = useCombinedClassName(styles.title, className, fontSizeClass);
    const combinedStyle = useCombinedStyle(style, { color: color ? color : Colors.BLACK90 });

    return (
        <h1 className={combinedClass} style={combinedStyle}>
            {children}
        </h1>
    );
};
export default Title;
