import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Image from '../../components/Base/Misc/Image';
import FlatList from '../../components/Base/Misc/FlatList';
import Article from '../../models/Article';
import CardArticleSmall from '../../components/Cells/CardArticleSmall';
import Brand from '../../models/Brand';
import CardBrand from '../../components/Cells/CardBrand';
import News from '../../models/News';
import CardNews from '../../components/Cells/CardNews';
import Promo from '../../models/Promo';
import ApiManager from '../../manager/ApiManager';
import View from '../../components/Base/View';
import Subtitle from '../../components/Base/Headings/Subtitle';
import useNavigation from '../../hooks/useNavigation';
import Title from '../../components/Base/Headings/Title';
import LoadingView from '../../components/LoadingView';
import Paragraph from '../../components/Base/Headings/Paragraph';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';

const Home = () => {
    const promo: Promo[] = [
        {
            id: 1,
            img: 'https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/bltdc6bd1410f7f0af7/66420fa766b81d3cb833d4de/Spring_Banner_Hellstar_PrimaryDesktop-Small.jpg?auto=webp&format=pjpg&width=1246&dpr=1&quality=80%201x,%20https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/bltdc6bd1410f7f0af7/66420fa766b81d3cb833d4de/Spring_Banner_Hellstar_PrimaryDesktop-Small.jpg?auto=webp&format=pjpg&width=1246&dpr=2&quality=75%202x,%20https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/bltdc6bd1410f7f0af7/66420fa766b81d3cb833d4de/Spring_Banner_Hellstar_PrimaryDesktop-Small.jpg?auto=webp&format=pjpg&width=1246&dpr=3&quality=50%203x',
        },
        {
            id: 2,
            img: 'https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/blt015ee148d6895aa9/6644bbf566b81d11be33eee3/Spring_Banner_Template_PrimaryDesktop-Small.jpg?auto=webp&format=pjpg&quality=75&dpr=2&width=1246',
        },
        {
            id: 3,
            img: 'https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/bltb61e127c966ed627/6644d2b64b531e8dfbc30b79/GiftsforGradsShop_PrimaryDesktop-Small.jpg?auto=webp&format=pjpg&quality=75&dpr=2&width=1246',
        },
    ];
    const [isLoading, setIsLoading] = useState(true);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [articles, setArticles] = useState<Article[]>([]);
    const [news, setNews] = useState<News[]>([]);
    const navigation = useNavigation();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let response = await ApiManager.getHomePageData();
            setBrands(response.data.brands);
            setArticles(response.data.articles);
            setNews(response.data.news);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, []);

    /*
     * Page Sections
     * */
    const PromoSection = useCallback(() => {
        if (promo.length === 0) {
            return null;
        }

        const renderSlides = (item: Promo) => {
            return (
                <View flexDirection={'row'} style={{ display: 'flex', whiteSpace: 'normal', width: 'inherit' }}>
                    <Image url={item.img} width={'100%'} height={200} objectFit={'cover'} style={{ objectPosition: 'left' }} />
                </View>
            );
        };

        const keyExtractor = (item: { id: number }) => {
            return item.id.toString();
        };

        return (
            <View id={'main-slider'} isSection paddingTop={16} paddingBottom={32} isBoxed>
                <FlatList autoplay horizontal data={promo} renderItem={renderSlides} keyExtractor={keyExtractor} elements={1} minElements={1} noOverlap />
            </View>
        );
    }, [promo]);
    const PopularBrandsSection = useCallback(() => {
        if (brands.length === 0) {
            return null;
        }

        const renderBrand = (item: Brand, index: number) => {
            return <CardBrand brand={item} onPress={() => navigation.navigateToBrand(item.slug)} />;
        };

        const keyExtractor = (item: { id: number }) => {
            return item.id.toString();
        };

        return (
            <View id={'popular-brands'} isSection isBoxed flexDirection={'column'} paddingTop={32} paddingBottom={32}>
                <View marginBottom={16}>
                    <Subtitle size={'L'}>Popular Brands</Subtitle>
                </View>
                <View>
                    <FlatList horizontal data={brands.slice(0, 10)} renderItem={renderBrand} keyExtractor={keyExtractor} gap={16} elements={4} minElements={1} />
                </View>
            </View>
        );
    }, [brands]);
    const NewsSection = useCallback(() => {
        if (news.length === 0) {
            return null;
        }

        const renderNews = (item: News, index: number) => {
            return <CardNews news={item} />;
        };

        const keyExtractor = (item: { id: number }) => {
            return item.id.toString();
        };

        return (
            <View id={'from-magazine'} isSection isBoxed flexDirection={'column'} paddingTop={32} paddingBottom={32}>
                <View marginBottom={16}>
                    <Subtitle size={'L'}>From Magazine</Subtitle>
                </View>
                <View>
                    <FlatList horizontal data={news} renderItem={renderNews} keyExtractor={keyExtractor} gap={16} elements={4} minElements={1} />
                </View>
            </View>
        );
    }, [news]);
    const BannerSection = useCallback(() => {
        return (
            <View id={'banners'} isSection isBoxed paddingTop={32} paddingBottom={32} gap={16} flexDirection={'row'}>
                <View flex={2}>
                    <Image
                        width={'100%'}
                        url={'https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/bltb967cb2a38081757/66324e5a99ab032a84b69443/NewBalance9060s_SecondaryA.jpg?format=jpg&auto=webp&height=438'}
                        aspectRatio={1.8}
                        objectFit={'cover'}
                        style={{ objectPosition: 'left' }}
                    />
                </View>
                <View flex={1} hideOn={'smartphone'}>
                    <Image
                        width={'100%'}
                        url={'https://images-cs.stockx.com/v3/assets/blt818b0c67cf450811/blt1d4085d335f8f6cd/66421f4fa3f9df372bc0d28f/NOCTA_LART_SecondaryB_(1).jpg?format=jpg&auto=webp&height=438'}
                        aspectRatio={1.8}
                        objectFit={'cover'}
                        style={{ objectPosition: 'left' }}
                    />
                </View>
            </View>
        );
    }, []);
    const ArticlesSection = useCallback(
        (props: { slug: string; title: string; data: Article[] }) => {
            const { slug, title, data } = props;

            if (data.length == 0) {
                return null;
            }

            const renderArticle = (item: Article, index: number) => {
                return <CardArticleSmall article={item} onPress={() => navigation.navigateToArticle(item.slug)} />;
            };

            const keyExtractor = (item: { id: number }) => {
                return item.id.toString();
            };

            return (
                <View id={slug} isSection isBoxed flexDirection={'column'} paddingTop={32} paddingBottom={32}>
                    <View marginBottom={16}>
                        <Subtitle size={'L'}>{title}</Subtitle>
                    </View>
                    <View>
                        <FlatList horizontal data={data} renderItem={renderArticle} keyExtractor={keyExtractor} gap={16} elements={6} />
                    </View>
                </View>
            );
        },
        [articles]
    );

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <PromoSection />
            <ArticlesSection slug={'recommended'} title={'Reccomended for you'} data={articles.slice(0, 6)} />
            <PopularBrandsSection />
            <ArticlesSection slug={'in-trend'} title={'In Trend'} data={articles.slice(6, 12)} />
            <ArticlesSection slug={'featured-apparel'} title={'Featured Apparel'} data={articles.slice(12, 18)} />
            <BannerSection />
            <ArticlesSection slug={'most-popular-around-you'} title={'Most Popular Around You'} data={articles.slice(0, 6)} />
            <NewsSection />
        </>
    );
};
export default Home;
