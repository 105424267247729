import React from 'react';
import styles from './style.module.css';
import View from '../../components/Base/View';
import Subtitle from '../../components/Base/Headings/Subtitle';

const NotFoundPage = () => {
    return (
        <View className={styles.page}>
            <Subtitle size={'L'}>Pagina non Trovata</Subtitle>
        </View>
    );
};
export default NotFoundPage;
