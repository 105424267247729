import React, { useEffect, useState } from 'react';
import Brand from '../../../models/Brand';
import styles from './style.module.css';
import Image from '../../Base/Misc/Image';
import Subtitle from '../../Base/Headings/Subtitle';
import Colors from '../../../toolkit/Colors';
import { NavLink } from 'react-router-dom';
import View from '../../Base/View';
import ContentLoader from 'react-content-loader';

interface CardBrandProps {
    brand: Brand;
    onPress?: () => void;
}

const CardBrand = (props: CardBrandProps) => {
    const { brand, onPress } = props;

    return (
        <View flexDirection={'column'} className={styles.container} onClick={onPress}>
            <View>
                <Image url={brand.img ? brand.img : ''} className={styles.img} aspectRatio={1.8} objectFit={'cover'} />
            </View>
            <View>
                <View flex={1} flexDirection={'row'} className={styles.titleContainer}>
                    <View flex={1}>
                        <Subtitle size={'M'} color={Colors.BLACK90}>
                            {brand.name}
                        </Subtitle>
                    </View>
                    <View>
                        <Image url={brand.logo ? brand.logo : ''} width={30} height={30} objectFit={'contain'} />
                    </View>
                </View>
            </View>
        </View>
    );
};
export default CardBrand;
