import apiClient from '../services/config';
import { GET_SETTINGS } from '../services/api';
import { RequestMethod } from '../models/enums/RequestMethod';
import SettingsData from '../models/responses/SettingsData';

class SettingsManager {
    static getAll = async () => {
        try {
            let response = await apiClient(GET_SETTINGS, {
                method: RequestMethod.GET,
            });
            return response.data as SettingsData;
        } catch (e) {
            console.log('getAllSettings error', e);
            throw e;
        }
    };
}

export default SettingsManager;
