import React, { useCallback, useEffect, useState } from 'react';
import View from '../Base/View';
import styles from '../../pages/Category/style.module.css';
import Paragraph from '../Base/Headings/Paragraph';
import FilterItem from '../../models/FilterItem';

interface FilterProps {
    elements: FilterItem[];
    onClick: (activeFilters: FilterItem[]) => void;
}

const Filter = (props: FilterProps) => {
    const { elements, onClick } = props;

    const [activeFilters, setActiveFilters] = useState<FilterItem[]>([]);

    useEffect(() => {
        setActiveFilters([]);
    }, [elements]);

    const handleClick = useCallback(
        (item: FilterItem) => {
            const isActive = activeFilters.some((filter) => filter.id === item.id);
            setActiveFilters((prevState) => {
                if (isActive) {
                    return prevState.filter((filter) => filter.id !== item.id);
                } else {
                    return [...prevState, item];
                }
            });
        },
        [activeFilters]
    );

    useEffect(() => {
        onClick(activeFilters);
    }, [activeFilters, onClick]);

    return (
        <>
            {elements.map((element) => {
                const isActive = activeFilters.some((filter) => filter.id === element.id);
                return (
                    <View
                        key={element.id}
                        id={element.id.toString()}
                        onClick={() => handleClick(element)}
                        className={`${styles.filterContainer} ${isActive ? styles.filterActiveContainer : ''}`}
                        paddingTop={8}
                        paddingBottom={8}
                    >
                        <Paragraph size={'S'} className={`${styles.filterText} ${isActive ? styles.filterActiveText : ''}`}>
                            {element.name}
                        </Paragraph>
                    </View>
                );
            })}
        </>
    );
};

export default Filter;
