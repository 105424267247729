import React, { forwardRef, useMemo } from 'react';
import styles from './style.module.css';
import useCombinedStyle from '../../../hooks/useCombinedStyle';
import useCombinedClassName from '../../../hooks/useCombinedClassName';

interface ViewProps {
    id?: string;
    children: React.ReactNode;
    flexDirection?: React.CSSProperties['flexDirection'];
    isSection?: boolean;
    flex?: React.CSSProperties['flex'];
    gap?: React.CSSProperties['gap'];
    alignItems?: React.CSSProperties['alignItems'];
    justifyContent?: React.CSSProperties['justifyContent'];
    paddingTop?: React.CSSProperties['paddingTop'];
    paddingBottom?: React.CSSProperties['paddingBottom'];
    marginTop?: React.CSSProperties['marginTop'];
    marginBottom?: React.CSSProperties['marginBottom'];
    isBoxed?: boolean;
    responsive?: boolean;
    hideOn?: 'smartphone' | 'tablet' | 'desktop';
    style?: React.CSSProperties;
    className?: string;
    onClick?: () => void;
    isLoading?: boolean;
}

const View = (props: ViewProps) => {
    const {
        id,
        children,
        isBoxed = false,
        isSection = false,
        flex,
        flexDirection,
        gap,
        style,
        alignItems,
        paddingBottom,
        paddingTop,
        marginTop,
        marginBottom,
        justifyContent,
        responsive = false,
        className,
        hideOn,
        onClick,
        isLoading = false,
    } = props;

    const isBoxedClass = useMemo(() => {
        return isBoxed ? styles.isBoxed : undefined;
    }, [isBoxed]);

    const isSectionClass = useMemo(() => {
        return isSection ? styles.isSection : undefined;
    }, [isSection]);

    const responsiveClass = useMemo(() => {
        return responsive ? styles.responsive : undefined;
    }, [responsive]);

    const isLinkClass = useMemo(() => {
        return onClick ? styles.isLink : undefined;
    }, [onClick]);

    const hideOnClass = useMemo(() => {
        const hideOnMap = {
            smartphone: styles.hideOnSmartphone,
            tablet: styles.hideOnTablet,
            desktop: styles.hideOnDesktop,
        };
        return hideOn ? hideOnMap[hideOn] : undefined;
    }, [hideOn]);

    const combinedStyle = useCombinedStyle(
        {
            flexDirection,
            gap,
            alignItems,
            justifyContent,
            paddingTop,
            paddingBottom,
            marginTop,
            marginBottom,
            flex,
            display: 'flex',
        },
        style
    );
    const combinedClassName = useCombinedClassName(styles.container, isBoxedClass, isSectionClass, responsiveClass, className, hideOnClass, isLinkClass);

    return (
        <div id={id} key={id} onClick={onClick} className={combinedClassName} style={combinedStyle}>
            {isLoading && <div>Loading...</div>}
            {!isLoading && children}
        </div>
    );
};
export default View;
