import React from 'react';
import styles from './style.module.css';
import View from '../../../../components/Base/View';
import Listing from '../../../../models/Listing';
import Subtitle from '../../../../components/Base/Headings/Subtitle';
import FlatList from '../../../../components/Base/Misc/FlatList';
import CardListing from '../../../../components/Cells/CardListing';

interface ListingProps {
    listings: Listing[];
}

const Listings = (props: ListingProps) => {
    const { listings } = props;

    const renderListing = (item: Listing, index: number) => {
        return <CardListing listing={item} />;
    };

    const keyExtractor = (item: { id: number }, index: number) => {
        return item.id.toString();
    };

    return (
        <>
            {listings.length === 0 && null}
            {listings.length > 0 && (
                <View id={'listings'} flexDirection={'column'} marginBottom={32} isBoxed>
                    <View flexDirection={'column'} isSection>
                        <View marginBottom={16}>
                            <Subtitle size={'L'}>Annunci</Subtitle>
                        </View>
                        <View>
                            <FlatList horizontal data={listings} renderItem={renderListing} keyExtractor={keyExtractor} gap={16} elements={5} minElements={2} />
                        </View>
                    </View>
                </View>
            )}
        </>
    );
};
export default Listings;
