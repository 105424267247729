import Article from '../models/Article';
import Brand from '../models/Brand';
import Category from '../models/Category';

const useFilterArticlesByBrand = (articles: Article[], filters: Brand[]) => {
    const filterBrandIds = filters.map((filter) => filter.id);
    return articles.filter((article) => article.brand && filterBrandIds.includes(article.brand.id));
};

const useFilterArticlesByCategories = (articles: Article[], filters: Category[]) => {
    const filterCategoriesIds = filters.map((filter) => filter.id);
    return articles.filter((article) => article.category && filterCategoriesIds.includes(article.category.id));
};

export { useFilterArticlesByBrand, useFilterArticlesByCategories };
