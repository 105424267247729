import React from 'react';
import styles from './card.listing.module.css';
import Listing from '../../../models/Listing';
import View from '../../Base/View';
import Image from '../../Base/Misc/Image';
import Paragraph from '../../Base/Headings/Paragraph';
import Colors from '../../../toolkit/Colors';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';

interface CardListingProps {
    listing: Listing;
}

const CardListing = (props: CardListingProps) => {
    const { listing } = props;

    const info = [
        { id: 1, key: 'Estetica', value: 8 },
        { id: 2, key: 'Batteria', value: 3 },
        { id: 3, key: 'Anno', value: 9 },
        { id: 4, key: 'Accessori', value: 10 },
    ];

    return (
        <View flexDirection={'column'} className={styles.container}>
            <View style={{ position: 'relative' }}>
                <Image className={styles.img} url={listing.main_img} width={'100%'} aspectRatio={1.6} objectFit={'cover'} />
                <View className={styles.heartIconContainer}>
                    <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                </View>
                <View className={styles.pqaContainer}>
                    <FontAwesomeIcon icon={faMedal} className={styles.pqaIcon} />
                    <Paragraph size={'S'} isBold color={'white'}>
                        {Math.floor(Math.random() * 10)}
                    </Paragraph>
                </View>
            </View>
            <View className={styles.infoContainer} flexDirection={'column'}>
                <View flexDirection={'row'} marginBottom={8} flex={1} className={styles.metaContainer} marginTop={8}>
                    {info.map((info) => {
                        return (
                            <View key={info.id} className={styles.metaView} paddingTop={2} paddingBottom={2} gap={4}>
                                <Paragraph size={'XS'} color={Colors.BLACK25} className={styles.metaKey}>
                                    {info.key}
                                </Paragraph>
                                <Paragraph size={'XS'} color={Colors.BLACK25} isBold>
                                    {info.value}
                                </Paragraph>
                            </View>
                        );
                    })}
                </View>
                <View flexDirection={'row'} flex={1}>
                    <View flexDirection={'row'} flex={1}>
                        <View flexDirection={'column'}>
                            <Paragraph size={'S'}>Prezzo</Paragraph>
                            <Paragraph isBold size={'L'} color={Colors.BLACK90}>
                                €{listing.price}
                            </Paragraph>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default CardListing;
