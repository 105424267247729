import React from 'react';
import styles from './style.module.css';
import Paragraph from '../../Base/Headings/Paragraph';
import useCombinedStyle from '../../../hooks/useCombinedStyle';
import useCombinedClassName from '../../../hooks/useCombinedClassName';
import View from '../../Base/View';

interface ButtonPrimaryProps {
    title: string;
    textColor: React.CSSProperties['color'];
    backgroundColor: React.CSSProperties['color'];
    onPress: () => void;
}

const ButtonPrimary = (props: ButtonPrimaryProps) => {
    const { title, backgroundColor, textColor, onPress } = props;
    const combinedClass = useCombinedClassName(styles.container);
    const combinedStyle = useCombinedStyle({ backgroundColor, color: textColor });

    return (
        <View className={combinedClass} style={combinedStyle} onClick={onPress}>
            <Paragraph size={'M'} isBold={true}>
                {title}
            </Paragraph>
        </View>
    );
};
export default ButtonPrimary;
