import { useNavigate } from 'react-router-dom';
import { PagePath } from '../models/enums/PagePath';

export default function useNavigation() {
    const navigate = useNavigate();

    const navigateTo = (url: string) => {
        navigate(url);
    };

    const navigateToHome = () => {
        navigateTo(PagePath.HOME);
    };

    const navigateToBrand = (brandSlug: string) => {
        navigateTo(PagePath.BRAND + '/' + brandSlug);
    };

    const navigateToArticle = (articleSlug: string) => {
        navigateTo(PagePath.ARTICLE + '/' + articleSlug);
    };

    const navigateToRegister = () => {
        navigateTo(PagePath.REGISTER);
    };

    const navigateToLogin = () => {
        navigateTo(PagePath.LOGIN);
    };

    const navigateToNews = () => {
        navigateTo(PagePath.NEWS);
    };

    const navigateToAbout = () => {
        navigateTo(PagePath.ABOUT);
    };

    const navigateToProfile = () => {
        navigateTo(PagePath.PROFILE);
    };

    const navigateToUrl = (url: string) => {
        navigateTo(url);
    };

    return {
        navigateToLogin,
        navigateToRegister,
        navigateToBrand,
        navigateToArticle,
        navigateToHome,
        navigateToAbout,
        navigateToNews,
        navigateToUrl,
        navigateToProfile,
    };
}
