import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApiManager from '../../manager/ApiManager';
import Article from '../../models/Article';
import Breadcrumb from '../../models/Breadcrumb';
import Brand from '../../models/Brand';
import Listing from '../../models/Listing';
import PriceHistory from './sections/PriceHistory';
import HistoricalData from './sections/HistoricalData';
import RelatedArticles from './sections/RelatedArticles';
import Listings from './sections/Listings';
import ArticleData from './sections/ArticleData';
import ArticleTitle from './sections/ArticleTitle';
import LoadingView from '../../components/LoadingView';

const ArticlePage = () => {
    const { slug } = useParams<{ slug: string }>();
    const [article, setArticle] = useState<Article>();
    const [brand, setBrand] = useState<Brand>();
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
    const [relatedArticles, setRelatedArticles] = useState<Article[]>([]);
    const [listings, setListings] = useState<Listing[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    /*
     * Get Article Data
     * */
    const fetchData = async () => {
        if (slug) {
            setIsLoading(true);
            try {
                let response = await ApiManager.getArticleData(slug);
                setArticle(response.article);
                setBrand(response.brand);
                setBreadcrumbs(response.breadcrumbs);
                setRelatedArticles(response.related);
                setListings(response.listings);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setIsLoading(false);
                navigate('*');
            }
        }
    };
    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [slug]);

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <ArticleTitle article={article} breadcrumbs={breadcrumbs} />
            <ArticleData article={article} />
            <Listings listings={listings} />
            <RelatedArticles relatedArticles={relatedArticles} />
            <PriceHistory />
            <HistoricalData />
        </>
    );
};
export default ArticlePage;
