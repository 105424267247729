import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import ArticlesPage from './pages/Articles';
import Home from './pages/Home';
import BrandPage from './pages/Brand';
import ArticlePage from './pages/Article';
import CategoryPage from './pages/Category';
import NotFoundPage from './pages/NotFound';
import ScrollToTop from './components/ScrollToTop';
import NavigationBar from './components/NavigationBar';
import RegisterPage from './pages/Register';
import LoginPage from './pages/Login';
import { AlertProvider } from './context/AlertContext';
import { PagePath } from './models/enums/PagePath';
import { UserProvider } from './context/UserContext';
import { SettingsProvider } from './context/SettingsContext';
import ProfilePage from './pages/Profile';
import SettingsPage from './pages/Settings';

function App() {
    return (
        <Router>
            <SettingsProvider>
                <UserProvider>
                    <AlertProvider>
                        <ScrollToTop />
                        <NavigationBar />
                        <div id="content">
                            <Routes>
                                {/* Unauth */}
                                <Route path="/" element={<Home />} />
                                <Route path={PagePath.BRAND + '/:slug'} element={<BrandPage />} />
                                <Route path={PagePath.CATEGORY + '/:slug'} element={<CategoryPage />} />
                                <Route path={PagePath.ARTICLES} element={<ArticlesPage />} />
                                <Route path={PagePath.ARTICLE + '/:slug'} element={<ArticlePage />} />
                                <Route path="*" element={<NotFoundPage />} />

                                {/* Signup/Signin */}
                                <Route path={PagePath.REGISTER} element={<RegisterPage />} />
                                <Route path={PagePath.LOGIN} element={<LoginPage />} />

                                {/* Auth */}
                                <Route path={PagePath.PROFILE} element={<ProfilePage />} />
                                <Route path={PagePath.SETTINGS} element={<SettingsPage />} />
                            </Routes>
                        </div>
                        <Footer />
                    </AlertProvider>
                </UserProvider>
            </SettingsProvider>
        </Router>
    );
}

export default App;
